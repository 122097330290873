<template>
  <div class="flix-row">
  <div class="flix-col-md-12">
    <div class="flix-form-group">
      <a class="flix-html-a" href="#" @click.prevent="open = !open"><flixIcon id="envelope" /> {{ data.length+ ' ' + $tc('message.customer', data.length) }}: {{ $t('message.generate', {name: $t('reminder.email')})  }}</a>
    </div>
  </div>
    <div v-if="open" class="flix-col-md-4">
      <div class="flix-well">
        <div class="flix-form-group">
          <h3 class="flix-html-h3" v-html="$t('message.title')" />
          <input type="text" v-model="title" class="flix-form-control" :placeholder="$t('message.title')">
        </div>
        <div class="flix-form-group">
          <h3 class="flix-html-h3" v-html="$t('reminder.email')" />
          <ckeditor :editor="editor" v-model="msg" :config="editorConfig"></ckeditor>
        </div>
        <div class="flix-form-group" v-if="title && msg">
          <saveBtn :settings="{block: true}" :onClick="function() { sendEmail() }">
            <template v-slot:text>{{ $t('message.send') }} ({{ data.length }})</template>
          </saveBtn>
        </div>
        <div class="flix-form-group" v-if="success">
          <div class="flix-alert flix-alert-success" v-html="$t('message.successfullSaved')"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/de'
export default {
  components: {},
  props: {
    data: Array
  },
  data () {
    return {
      open: false,
      editor: ClassicEditor,
      title: '',
      msg: '',
      editorConfig: {
        toolbar: ['heading', '|', 'bold', 'italic', '|', 'link', '|', 'bulletedList', 'numberedList'],
        language: 'de'
      },
      user: this.$getUserVariables(),
      success: false
    }
  },
  methods: {
    sendEmail () {
      var send = {
        msg: this.msg,
        title: this.title,
        users: this.getUsers(),
        user: this.user.user.md5_id
      }
      this.$flix_post({
        url: 'email/send_all',
        data: send,
        callback: function (ret) {
          if (ret.data[0]) {
            this.success = true

            setTimeout(function () {
              this.success = false
            }.bind(this), 2000)
          }
        }.bind(this)
      })
    },
    getUsers () {
      var data = []
      this.data.forEach(function (d) {
        data.push({ email: d.email, form: d.form, ID: d.ID })
      })
      return data
    }
  },
  mounted () {
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
